import React from "react"
import { Script } from 'gatsby';
import { Helmet } from "react-helmet"
import { LWrap, MicroCmsImage, CJumbotron, CBreadCrumb } from "../../components/_index"
import formatDateJa from "../../utils/format-date-ja"
import periodText from "../../utils/period-text"
import MicroCmsContents from "./microcms_contents"

const BanquestPlanLayout = ({ data }: { data: any }) => {

  return (
    <>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function(){
    const sentenceAtag = document.querySelectorAll('.js_articleSentence a');
    for (let i = 0; i < sentenceAtag.length; i++) {
      const target = sentenceAtag[i].getAttribute('target')
      const icon_blank = document.createElement("i")
      icon_blank.className = 'icon-blank';
      const pdf_check = /\.pdf$/.test(sentenceAtag[i].getAttribute('href'))
      const icon_pdf = document.createElement("i")
      icon_pdf.className = 'icon-pdf';
      if(pdf_check){
        sentenceAtag[i].prepend(icon_pdf);
      }else if(target == "_blank"){
        sentenceAtag[i].appendChild(icon_blank);
      }
    }
})(document)
        `}} />
      <section className="l_sect05">
        <LWrap exClass="l_wrap__small">
          <div className="c_articleHeading u_mb50">
            <h2 className="title">{data.title}</h2>
          </div>
          {data.eyecatch && (
            <figure className="c_articleKV u_mb20">
              <MicroCmsImage image={data.eyecatch} alt="" loading="lazy" />
            </figure>
          )}

          {data.description && <div className="lead u_mb20">
            <p>{data.description.split('\n').map((t: string) => (<>{t}<br /></>))}</p>
          </div>}

          <div className="u_mb60">
            <div className="c_inlineDefinitionUnit c_inlineDefinitionUnit__col2 c_inlineDefinitionUnit__right">
              <div className="col">
                {data.period &&
                  <div className="c_inlineDefinition">
                    <dl>
                      <dt className="title">期間</dt>
                      <dd className="text">{data.period.split('\n').map((t: string) => (<>{t}<br /></>))}</dd>
                    </dl>
                  </div>
                }
                {data.fee &&
                  <div className="c_inlineDefinition">
                    <dl>
                      <dt className="title">料金</dt>
                      <dd className="text">{data.fee.split('\n').map((t: string) => (<>{t}<br /></>))}</dd>
                    </dl>
                  </div>
                }
              </div>
            </div>
          </div>
          <MicroCmsContents data={data.contents} />
          <ul className="c_btnList c_btnList__center">
            <li className="item">
              <a href="/banquet/plan/" className="c_btn c_btn__borderBlack">ご宴会プラン一覧に戻る</a>
            </li>
          </ul>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
            {
              label: 'ご宴会プラン',
              path: '/banquet/plan/',
            },
          ],
          current: {
            label: data.title,
          },
        }}
      />
    </>
  )
}
export default BanquestPlanLayout;
